import { Segment } from "#app/(unauthorized)/authentication/jwt";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { mfaAnalyzeBase } from "#app/_ui/components/mfa/api";
import {
  getJson,
  postJson,
  postJsonWithoutBody,
  putJson,
} from "#app/lib/fetchClient";
import { TimeSpan } from "#app/lib/TimeSpan";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  keepPreviousData,
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

const STALE_TIME = TimeSpan.fromMinutes(1).totalMilliseconds;

export interface UserEmail {
  verifiedEmail?: string;
  unverifiedEmail?: string;
}

interface AssociateWithEncryptedEmpId {
  order: number;
  name: string;
  officerTitle: string;
  professionalTitle: string;
  email: string;
  phone: string;
  encryptedEmpId: string;
}

export interface ExternalBranchAndTeamMembers {
  repCode: string;
  branchDisplayName: string;
  branchPhone: string;
  branchUrl: string;
  branchAddress1: string;
  branchAddress2: string;
  branchCity: string;
  branchState: string;
  branchPostalCode: string;
  teamMembers: AssociateWithEncryptedEmpId[];
}

export type ExternalBranchAndTeamMembersMO = {
  repCode: string;
  branchDisplayName: string;
  branchPhone: string;
  branchUrl: string;
  branchAddress1: string;
  branchAddress2: string;
  branchCity: string;
  branchState: string;
  branchPostalCode: string;
  teamMembers: TeamMember[];
};

export type TeamMember = {
  order: number;
  name: string;
  officerTitle: string;
  professionalTitle: string;
  email: string;
  phone: string;
  encryptedEmpId: string;
  photoApproved: string;
};

type AssociatePhoto = {
  data: string;
};

type verifiedEmail = {
  email: string;
  isVerified: boolean;
};

interface PhoneNumbers {
  faxPhones: string[] | [string];
  homePhones: string[] | [string];
  otherPhones: string[] | [string];
  workPhones: string[] | [string];
}

export interface PhoneNumberUpdate {
  homePhone: string;
  workPhone: string;
  otherPhone: string;
  faxPhone: string;
}

export interface FriendlyNameUpdate {
  friendlyName: string;
}

interface MetaData { }

type AccountGroupService = {
  id: string;
  name: string;
  acctIndexes: number[];
};

type AccountGroup = {
  id: string;
  name: string;
  accounts: string[];
};

export type Nickname = {
  accountNumber: string;
  name: string;
};

const GATEWAY_PATH = "UserProfiles/v1";

export async function contactUs(message: string): Promise<void> {
  return postJson<void>(`/${GATEWAY_PATH}/Help?type=TechnicalHelpRequest`, {
    message: message,
  });
}

export async function getFAInfo(): Promise<ExternalBranchAndTeamMembers> {
  return getJson<ExternalBranchAndTeamMembers>(
    `/${GATEWAY_PATH}/FinancialAdvisor/FAInformation`,
  );
}

export function getVerificationStatus(email: UserEmail) {
  return email.verifiedEmail != null && email.unverifiedEmail == null;
}

export async function isEmailVerified(): Promise<verifiedEmail> {
  const email = await getEmail();
  return {
    isVerified: getVerificationStatus(email),
    email: email.verifiedEmail ?? "", //TODO: make sure this is correct
  };
}

export async function getEmail(): Promise<UserEmail> {
  const result = await getJson<UserEmail>(`/${GATEWAY_PATH}/Email`);
  return result;
}

export async function updateEmail(newEmailAddress: string): Promise<void> {
  await postJsonWithoutBody(
    `/${GATEWAY_PATH}/Email/ChangeRequest?newEmailAddress=${encodeURIComponent(newEmailAddress)}`,
  );
}

export async function updateEmailResend(): Promise<void> {
  await putJson(`/${GATEWAY_PATH}/Email/ChangeRequestResend`);
}

export async function verifyEmail(token: string): Promise<void> {
  await postJson(`/${GATEWAY_PATH}/Email/Verify`, {
    token,
  });
}

export async function mfaAnalyze() {
  const result = await mfaAnalyzeBase(GATEWAY_PATH);
  return result;
}

export function getPhoneNumbers(): Promise<PhoneNumbers> {
  return getJson<PhoneNumbers>(`/${GATEWAY_PATH}/PhoneNumber`);
}

export function putPhoneNumbers(
  phoneNumbers: PhoneNumberUpdate,
): Promise<void> {
  return putJson<void>(`/${GATEWAY_PATH}/PhoneNumber`, phoneNumbers);
}

export function usePhoneNumbersMutation() {
  const queryClient = useQueryClient();
  const { mutateAsync: updatePhoneNumbers, ...rest } = useMutation({
    mutationFn: (form: PhoneNumberUpdate) => putPhoneNumbers(form),
    onSettled: () =>
      queryClient.invalidateQueries(userProfileQueryKeys.phoneNumbers),
  });

  return { updatePhoneNumbers, ...rest } satisfies Omit<
    ReturnType<typeof useMutation>,
    "mutateAsync" | "mutate"
  > & { updatePhoneNumbers: typeof updatePhoneNumbers };
}

export function getFriendlyName(): Promise<string> {
  return getJson<string>(`/${GATEWAY_PATH}/FriendlyName`);
}

function putFriendlyName(friendlyName: FriendlyNameUpdate): Promise<void> {
  return putJson<void>(
    `/${GATEWAY_PATH}/FriendlyName?newFriendlyName=${encodeURIComponent(friendlyName.friendlyName)}`,
  );
}

export function useFriendlyNameMutation() {
  const queryClient = useQueryClient();
  const { mutateAsync: updateFriendlyName, ...rest } = useMutation({
    mutationFn: (form: FriendlyNameUpdate) => putFriendlyName(form),
    onSettled: () =>
      queryClient.invalidateQueries(userProfileQueryKeys.friendlyName),
  });

  return { updateFriendlyName, ...rest } satisfies Omit<
    ReturnType<typeof useMutation>,
    "mutateAsync" | "mutate"
  > & { updateFriendlyName: typeof updateFriendlyName };
}

export async function getFAInformation(): Promise<ExternalBranchAndTeamMembersMO> {
  const result: ExternalBranchAndTeamMembersMO = await getJson(
    `/${GATEWAY_PATH}/FinancialAdvisor/FAInformation`,
  );

  return result;
}

export function useGetFaInformationQuery() {
  return useQuery({
    ...userProfileQueryKeys.faInfo,
    placeholderData: keepPreviousData,
  });
}

export async function getAssociatePhoto(encryptedEmpId: string) {
  return await getJson<AssociatePhoto>(
    `/${GATEWAY_PATH}/FinancialAdvisor/AssociatePhoto?encryptedEmpId=${encodeURIComponent(encryptedEmpId)}`,
  );
}

export function useGetAssociatePhotoQuery(encryptedEmpId: string) {
  return useQuery({
    ...userProfileQueryKeys.associatePhoto(encryptedEmpId),
    placeholderData: keepPreviousData,
  });
}

export async function changeAlias(oldAlias: string, newAlias: string) {
  await putJson(
    `/${GATEWAY_PATH}/Alias?oldAlias=${encodeURIComponent(oldAlias)}&newAlias=${encodeURIComponent(newAlias)}`,
    "",
  );
}

export async function getMetaData(): Promise<MetaData> {
  const result: MetaData = await getJson(`/${GATEWAY_PATH}/MetaData`);
  return result;
}

export async function getAccountGroups(): Promise<AccountGroupService[]> {
  const result: AccountGroupService[] = await getJson(
    `/${GATEWAY_PATH}/AccountGroups`,
  );
  return result;
}

export async function getAccountNickNames(): Promise<Nickname[]> {
  const result: Nickname[] = await getJson(
    `/${GATEWAY_PATH}/AccountNickname/All`,
  );
  return result;
}

export const userProfileQueryKeys = createQueryKeys("profile", {
  accountGroups: {
    queryKey: null,
    queryFn: () => getAccountGroups(),
  },
  nicknames: {
    queryKey: null,
    queryFn: () => getAccountNickNames(),
  },
  faInfo: {
    queryKey: null,
    queryFn: () => getFAInformation(),
  },
  phoneNumbers: {
    queryKey: null,
    queryFn: () => getPhoneNumbers(),
  },
  friendlyName: {
    queryKey: null,
    queryFn: () => getFriendlyName(),
  },
  associatePhoto: (encryptedEmpId: string) => ({
    queryKey: [encryptedEmpId],
    queryFn: () => getAssociatePhoto(encryptedEmpId),
  }),
});

export function useFaInfoQueryOptions() {
  return queryOptions({
    ...userProfileQueryKeys.faInfo,
    staleTime: Infinity,
    throwOnError: false,
  });
}

export function useAccountGroupsQueryOptions() {
  const jwt = useJwt();
  const isEnabled =
    jwt.requiredActivities.length == 0 &&
    [Segment.IndividualInvestors, Segment.PrivateAssetManagement].includes(
      jwt.segment,
    );

  const defaultValue: AccountGroupService[] = [];
  return queryOptions({
    queryKey: userProfileQueryKeys.accountGroups.queryKey,
    queryFn: (context) =>
      isEnabled
        ? (
          userProfileQueryKeys.accountGroups.queryFn(context) as Promise<
            AccountGroupService[]
          >
        ).catch((_) => defaultValue)
        : defaultValue,
    select: function (result) {
      return result.map(
        (y) =>
          ({
            id: y.id,
            name: y.name,
            accounts: jwt.getAccountFromIndex(y.acctIndexes),
          }) as AccountGroup,
      );
    },
    staleTime: isEnabled ? Infinity : 0,
    gcTime: isEnabled ? 0 : undefined,
    throwOnError: false,
  });
}

export function useAccountNickNameQueryOptions() {
  const jwt = useJwt();
  const isEnabled =
    jwt.requiredActivities.length == 0 &&
    [Segment.IndividualInvestors, Segment.PrivateAssetManagement].includes(
      jwt.segment,
    );
  const defaultData: Nickname[] = [];
  return queryOptions({
    queryKey: userProfileQueryKeys.nicknames.queryKey,
    queryFn: (context) =>
      isEnabled
        ? (
          userProfileQueryKeys.nicknames.queryFn(context) as Promise<
            Nickname[]
          >
        ).catch((_) => defaultData)
        : defaultData,
    staleTime: isEnabled ? Infinity : 0,
    gcTime: isEnabled ? 0 : undefined,
    throwOnError: false,
  });
}

export function usePhoneNumbersQuery() {
  return useQuery(
    queryOptions({
      ...userProfileQueryKeys.phoneNumbers,
      staleTime: STALE_TIME,
    }),
  );
}

export function useFriendlyNameQuery() {
  return useQuery(
    queryOptions({
      ...userProfileQueryKeys.friendlyName,
      staleTime: STALE_TIME,
    }),
  );
}
